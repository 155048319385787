import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const LoginRoute = ({ user, children }) => {
    let data = useSelector((state) => state.auth);
    let role = data?.data?.user_type;
    if (role) {
        if(role == 1){
            return <Navigate to="/admin/dashboard" replace />;
        } else {
            return <Navigate to="/dashboard" replace />;
        }
    }
    return children;
};

export default LoginRoute;
